<template>
  <h1>Soy dashboard</h1>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">

</style>
